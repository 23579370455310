<template>
  <div class="flex column">
    <a
      v-if="downloadLink"
      class="btn btn-secondary"
      :href="`//${downloadLink}&authorization=${$store.state.accessToken}`"
    >
      <download-icon />
    </a>

    <button
      class="btn btn-secondary btn-sm trimtext upload-button"
      @click="pickFile"
    >
      <template v-if="!fileName">{{
        $store.state.translations.SelectFile
      }}</template>
      <template v-else>{{ fileName }}</template>

      <upload-icon class="ml-2" />
    </button>
    <input
      ref="fileInput"
      type="file"
      @change="onFilePicked"
      style="display: none"
    />
  </div>
</template>

<script>
import DownloadIcon from "../icons/DownloadIcon.vue";
import UploadIcon from "../icons/UploadIcon.vue";

export default {
  components: {UploadIcon, DownloadIcon},
  name: "FormFieldSingleUpload",
  props: {
    value: {
      type: [String, Object],
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      downloadLink: null,
      base64File: null,
      fileName: null,
      file: null,
    };
  },
  created() {
    this.downloadLink = this.value;
  },
  computed: {
    fieldValue() {
      if (!this.fileName || !this.base64File) return null;
      return {
        fileName: this.fileName,
        base64File: this.base64File,
      };
    },
  },
  watch: {
    fieldValue() {
      this.$emit("input", this.fieldValue);
    },
  },
  methods: {
    pickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      console.log({event, name: files[0].name});

      this.fileName = files[0].name;
      const fileReader = new FileReader();

      fileReader.addEventListener("load", () => {
        this.base64File = fileReader.result;
      });

      fileReader.readAsDataURL(files[0]);

      this.file = files[0];
    },
  },
};
</script>

<style scoped>
.upload-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  height: 30px;
}
</style>
